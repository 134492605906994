<template>
  <div class="container">
    <Head />
    <Swiper />
    <Games />
    <News />
    <Join />
    <Foot />
  </div>
</template>
<script>
import Head from '../modules/Head.vue';
import Swiper from '../modules/Swiper.vue';
import Games from '../modules/Games';
import News from '../modules/News.vue';
import Join from '../modules/Join.vue'
import Foot from '../modules/Foot.vue'

export default {
  name: "Home",
  components: { Head, Swiper, Games, News, Join, Foot }

}
</script>
<style></style>

